@use '/src/assets/sass/index.scss' as * ;

.notes_panel_default_button{
    background-color: rgba(255, 255, 255, 0.5)!important;
    border-radius: 4px!important;

    &:hover, &:active{
        background-color: rgba(255, 255, 255, 0.655)!important;
    }
}

.notes_editor_button{
    background-color: $realm-green!important;
    font-family: 'DM Sans'!important;
    color: $off-white!important;
    
    &_delete{
        font-family: 'DM Sans'!important;
        background-color: red!important;
        color: $off-white!important;
    }
    
    &:hover{
        background-color: $milky-brown!important;
        color: $dark-brown!important;
    }
}