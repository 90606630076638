@use '/src/assets/sass/index.scss' as * ;

.styled_tab{
  text-transform: capitalize!important;
  font-weight: 400;
  font-size: 1rem;
  margin-right: 0;
  padding:1.5rem 0;
  height: 14px;
  min-height: 14px;
  border-radius: 0;

  &:not(:first-of-type) {
    border-radius: 0;
  }
  
  &:first-of-type {
    border-radius: 4px 0px 0px 0px;
  }
  
  &:last-of-type {
    border-radius: 0px 4px 0px 0px;
  }
  
  &.light{
    background-color: $toolbar-background-light;
    color: $panel-tab-unselected-color;
    
    &.Mui-selected {
      color: $white!important;
      background-color: $toolbar-background-light;
    }

  }

  
  &.dark{
    background-color: $toolbar-background-dark;
    color: $panel-tab-unselected-color;

    &.Mui-selected{
      color: $white!important;
      background-color: $toolbar-background-dark;
    }
  }
  
  // .css-bsh4be-MuiTabs-root button {
  //     border: none !important;
  //     position: relative;
  //     width: 50%;
  //     margin: 0 auto;
  //   }
    
  //   .css-bsh4be-MuiTabs-root button::before,
  //   .css-bsh4be-MuiTabs-root button::after {
  //     content: '';
  //     position: absolute;
  //     bottom: 0;
  //     height: 4px;
  //     width: 60px;
  //     background-color: #141218;
  //     border-radius: 10px;
  //   }
    
  //   .css-bsh4be-MuiTabs-root button.Mui-selected::before,
  //   .css-bsh4be-MuiTabs-root button.Mui-selected::after {
  //     background-color: #d0bcff;
  //   }
  
}

.styled_tabs{
  min-height: 14px;
  margin-top: 16px;
  
  & .MuiTabs-indicator {
    display: flex;
    justify-content: center;
    background-color: transparent;
  }

  & .MuiTabs-indicator::after {
    content: "";
    width: 39px!important;
    background-color: $white!important;
    height: 3px!important;
    border-radius: 100px 100px 0px 0px;
  }
}
