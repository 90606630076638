@use '../sass/main.scss' as *;

.main {
  padding: 20px;
  // margin-bottom: 48px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px;
    border-bottom: 1px solid $beige;

    @include media('<=tablet') {
      display: none;
    }

    &__backgroundEmpty {
      width: 223px;
    }

    &__nameEmpty {
      width: 400px;
    }

    &__descriptionEmpty {
      width: 300px;
    }

    &__tokenEmpty {
      width: 360px;
    }

    &__actionsEmpty {
      width: 217px;
    }

    &__name {
      color: $dark-brown;
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      // padding-left: 313px;
    }

    &__description {
      color: $dark-brown;
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      // padding-left: 188px;
    }

    &__token {
      color: $dark-brown;
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      // padding-left: 409px;
    }
  }

  .detail {
    padding: 20px;
    border-bottom: 1px solid $beige;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media('<=tablet') {
      flex-direction: column;
      justify-content: center;
    }

    &__background {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      max-width: 360px;
      max-height: 240px;
      min-width: 240px;
      min-height: 160px;
      border-radius: 5px;
      border: 3px solid $white-2;
      overflow: hidden;

      &__image {
        width: 100%;
        height: 100%;
      }

      &__star {
        width: 27.328px;
        height: 27.328px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        position: absolute;
        top: 12px;
        left: 12px;
        z-index: 2;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      width: 100%;

      @include media('<=tablet') {
        gap: 10px;
        padding-bottom: 10px;
      }

      &__heading {
        color: $dark-brown;
        font-family: 'Roboto', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;

        @include media('<=tablet') {
          text-align: center;
          width: 100%;
          font-size: 20px;
        }
      }

      &__bottom {
        @include flex(flex-start, center, 8.22px);

        @include media('<=tablet') {
          justify-content: center;
        }
      }

      &__tag {
        background-color: $light-brown;
        border-radius: 65px;
        display: flex;
        width: 100px;
        height: 22px;
        justify-content: center;
        align-items: center;
        color: $white-2;

        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0.1px;

        @include media('<=tablet') {
          width: 80px;
          font-size: 12px;
        }
      }

      &__longTag {
        background-color: $light-brown;
        border-radius: 65px;
        display: flex;
        width: 105px;
        height: 17.353px;
        justify-content: center;
        align-items: center;
        color: $white-2;

        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;

        @include media('<=tablet') {
          width: 90px;
          font-size: 9px;
        }
      }
    }

    &__description {
      width: 100%;
      color: $dark-brown;
      font-family: 'DM Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;

      @include media('<=tablet') {
        text-align: center;
        font-size: 12px;
      }
    }

    &__tokenContainer {
      display: flex;
      width: 100%;

      &__token {
        height: 30%;
        width: 30%;
        min-width: 80px;
        min-height: 80px;
        border-radius: 50%;
        object-fit: cover;
        border: 4px solid $realm-green;
      }

      &__text {
        color: $dark-brown;
        font-family: 'DM Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        // justify-self: center !important;

        @include media('<=tablet') {
          text-align: center;
          width: 100%;
          padding-bottom: 10px;
          font-size: 12px;
        }
      }
    }

    &__actions {
      @include flex(flex-start, center, 8px);
      //   justify-self: flex-end;
      // padding-left: 93px;
    }

    &__enterButton {
      width: 165px;
      height: 40px;
      border-radius: 4px;
      //   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      outline: none;
      border: none;
      background-color: $white-2;
      z-index: 2;
      justify-self: flex-end;
      display: block;

      color: $dark-brown;
      text-align: center;
      font-family: 'Passero One', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1px;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $white-2;
        background-color: $realm-green;
      }
    }

    &__settingsContainer {
      width: 40px;
      height: 40px;
      background-color: $white-2;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      & svg {
        width: 30px;
        height: 30px;
        color: $dark-brown;
      }
    }
  }
}
