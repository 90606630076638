@use '/src/assets/sass/index.scss' as * ;
.rwcolorpicker_saturation{
    .saturation-white>div:nth-of-type(2)>div{
        width:10px!important;
        height: 10px!important;
    }
    // transform: scaleX(-1) scaleY(-1);
}

.rwcolorpicker_form_parent_wrapper{
    & .MuiInputBase-input.MuiInput-input{
        text-align: right!important;
    }
} 

.rwcolorpicker_hue{
    .hue-horizontal>div{
        top: 50%;
        transform: translateY(-50%);
        
        & >div{
            width: 16px!important;
            height: 16px!important;
            background-color: transparent!important;
            border: solid 2px white !important;
            margin-top: -1px !important;
            box-shadow: none !important;
            transform: translateX(-8px)!important;
        }
    }
}

.rwcolorpicker_wrapper{
    margin: 0px;
    max-height: $panel-tab-height;
    overflow-y: scroll;
    overflow-x: hidden;
    
    &::-webkit-scrollbar{
      width: 5px;
      padding-right: 10px;
    }
    
    
    /* Thumb (the draggable part of the scrollbar) */
    &.light{
      /* Firefox*/
      &.scrollbar {
        scrollbar-width: thin; 
        scrollbar-color: $toolbar-background-light transparent !important;
      }
      
      &::-webkit-scrollbar-thumb, &.scrollbar-thumb {
        background-color: $milky-brown !important;
        border-radius: 5px;
        padding-right: 10px;
      } 
      &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover {
        background-color: $toolbar-divider-light  !important;
      }
    }
    
    &.dark{
      /* Firefox*/
      &.scrollbar {
        scrollbar-width: thin; 
        scrollbar-color: $toolbar-background-dark transparent !important;
      }
  
      &::-webkit-scrollbar-thumb, &.scrollbar-thumb {
        background-color: $toolbar-divider-dark !important;
        border-radius: 5px;
        padding-right: 10px;
      }
      &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover {
        background-color: $light-grey !important;
      }
    }
  
    .active-background, .active-background:hover{
      padding: 10px;
      transition: $standard-transition;
      img{
        transition: $standard-transition;
        filter: drop-shadow(0 0 5px #ffffffb3) drop-shadow(0 0 10px #ffffffb3) drop-shadow(0 0 15px #ffffffb3);
      }
    }
  
  }
  
@media screen and (min-height:900px) {
    .rwcolorpicker_wrapper{
    max-height: $panel-tab-height-720;
    }
}
