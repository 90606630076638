@use '/src/assets/sass/index.scss' as *;

.panelBox_wrapper {
  min-height: 100%;
  // width: $panel-tab-width;
  pointer-events: none;
}

@media screen and (max-width: 900px) {
  .panelBox_wrapper {
    min-height: 100%;
    position: absolute;
    top: -100px;
    // padding-bottom: 200px;
  }
}

@media screen and (max-width: 500px) {
  .panelBox_wrapper {
    min-height: 100%;
    width: 100vw;
    left: -16px;
    position: absolute;
    top: 0px;
  }
}
