@use '../sass/main.scss' as *;

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;

  .main {
    display: flex;
    height: 100%;

    &__container {
      flex: 1;
      background-image: url('../../assets/images/NewDashboard/wallpaper_light.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      overflow-y: scroll;
      transition: margin-left 0.3s ease;
    }

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 228px 1fr;

      .sidebar {
        position: static;
        transform: none;
        width: 228px;
      }

      .main__container {
        margin-left: 0;
      }
    }
  }
}
