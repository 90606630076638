@use '/src/assets/sass/index.scss' as * ;

.filter_dropdown_list{
    background-color: $realm-green;
    color: $white;
    padding: 4px 0;
    border-radius: 4px;

    .filter_dropdown_list_item{
        height: 25px;
        display: flex;
        justify-content: space-between;

        .filter_dropdown_list_item_text{
            text-transform: uppercase;
            
            span{
                font-family: "DM Sans"!important;
                font-size: 12px!important;
                font-style: normal!important;
                font-weight: 400!important;
                line-height: 26px!important; /* 216.667% */
                letter-spacing: 0.46px!important;
            }
        }
    }
}