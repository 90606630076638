@use '../sass/main.scss' as *;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $milky-brown;
  min-height: 48px;
  // padding-right: 20px;
  width: 100%;

  @include media('<=tablet') {
    min-height: 24px;
    padding-right: 0;
  }
}
