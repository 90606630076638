@use '/src/assets/sass/index.scss' as * ;

.empty_card_box{
    height: 250px;
    width: 250px;
    background-color: #898989;
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty_card_text{
    color: #000;
    text-align: center;
    font-size: 64px!important;
    font-family: "Passero One"!important;
    font-weight: 400!important;
    line-height: 20px!important;
    letter-spacing: 0.1px!important;
}

.image_card{
    cursor: pointer;

    
    .image_card_image{
        height: 250px;
        width: 250px;
        object-fit: cover;
        object-position: center;
        box-shadow: none;
        transform: .3s ease-in-out;
        
        &:hover{
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.50), 0px 4px 10px 0px rgba(0, 0, 0, 0.50);
        }
    }
}

@media screen and (max-height: 900px) {
    .empty_card_box{
        height: 200px;
        width: 200px;
    }

    .image_card{
        .image_card_image{
            height: 200px!important;
            width: 200px!important;
        }
    }
}