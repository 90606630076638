@use '/src/assets/sass/index.scss' as *;

.preview_title_wrapper {
  background-color: #181818;
  border-radius: 10px 10px 0px 0px;

  @media screen and (max-width: 865px) {
    display: none;
  }

  .preview_title {
    text-align: center;
    font-size: 24px;
    font-family: 'Passero One' !important;
    color: $white;
  }
}

.preview_export_button {
  background-color: #181818 !important;
  border-radius: 0 !important;
  color: $white !important;
  font-family: 'Passero One' !important;
  font-size: 20px !important;

  &:hover {
    background-color: $milky-brown !important;
    color: $dark-brown !important;
  }
}

.preview_save_button {
  background: $realm-green !important;
  border-radius: 0px 0px 10px 10px !important;
  color: $white !important;
  font-family: 'Passero One' !important;
  font-size: 20px !important;

  //   &:hover {
  //     background: $realm-green !important;
  //     color: $dark-brown !important;
  //   }
}

.save_modal_input {
  width: 400px;
  border: 1px solid $white;
  padding: 10px 20px;
  color: $white;
  border-radius: 5px;

  input {
    font-family: 'Passero One';
    font-size: 20px;
    font-weight: 400;
    color: $white;
  }

  &::after,
  &::before {
    border: none !important;
    outline: none !important;
  }

  &:hover {
    border: 1px solid $white;

    &::after,
    &::before {
      border: none !important;
      outline: none !important;
    }
  }
}

.save_modal_button {
  background-color: $realm-green !important;
  color: $off-white !important;
  padding: 10px 20px;

  &:hover {
    background-color: $milky-brown !important;
    color: $dark-brown !important;
  }
}

@media screen and (max-width: 865px) {
  .preview_title_wrapper {
    display: none;
  }
}
