@use '/src/assets/sass/index.scss' as *;

.toolkit {
  display: flex;
  width: fit-content;
  height: 47px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 0.5px solid;
  box-sizing: border-box;
  column-gap: 0;
  z-index: 200;
  position: fixed;
  top: 80px;
  left: 16px;
  transform: translateZ(120px);
  transition: ease-in-out 0.3s;

  .toolkit_button {
    height: 100%;
    width: 56px;
    padding: 12px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;

    .toolkit_menu_icon {
      height: 24px;
      width: 24px;
      object-fit: contain;
    }
  }

  .toolkit_divider_wrapper {
    display: flex;
    width: 1px;
    height: 47px;
    padding: 0px;
    align-items: center;

    &::before {
      content: ' ';
      width: 1px;
      height: 15px;
    }
  }

  &.light {
    background-color: $toolbar-background-light;
    // background-color: $realm-green;
    border-color: $toolbar-divider-light;

    .toolkit_divider_wrapper {
      &::before {
        background-color: $toolbar-divider-dark !important;
      }
      &.white {
        &::before {
          background-color: white !important;
        }
      }
    }
  }

  &.dark {
    background-color: $toolbar-background-dark;
    border-color: black;

    .toolkit_divider_wrapper {
      &::before {
        background-color: $toolbar-divider-dark;
      }
      &.white {
        &::before {
          background-color: white !important;
        }
      }
    }
  }
}

.heading {
  font-family: 'Passero One' !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 100% */
  letter-spacing: 0.1px;
  padding-right: 12px;

  &.light {
    color: $dark-brown;
  }

  &.dark {
    color: $white;
  }
}

.headingIcon {
  height: 50px;
  width: auto;

  &.light {
    .cls-2 {
      fill: $dark-brown !important;
    }
  }

  &.dark {
    .cls-2 {
      fill: $white !important;
    }
  }
}

.headingButton {
  border-radius: 5px;
  background: $realm-green;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: block;
  border: none;
  outline: none;
  padding: 7px 10px;
  cursor: pointer;

  &.ai-gen {
    background: $realm-ai;
  }

  .headingButtonTitle {
    color: white;
    font-family: 'Passero One';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.my_custom_searchbox.cp {
  border-bottom: 1px solid #000;
  background-color: transparent;
  .input-group {
    box-shadow: none;

    textarea {
      font-family: 'DM Sans';
      font-weight: 400;
      font-size: 15px;
      line-height: 26px;
      letter-spacing: 0.46px;
      background-color: transparent;
      color: black;
      width: 530px;

      @media screen and (max-width: 768px) {
        width: 285px !important;
      }

      @media screen and (max-width: 500px) {
        width: 170px !important;
      }
    }

    svg {
      fill: black;
    }

    // hides the button with a slash inside the search box
    &
      > div:first-of-type
      > div:first-of-type
      > div:first-of-type
      > div:first-of-type {
      display: none;
    }
  }
}

.my_custom_searchbox.realm {
  border-bottom: 1px solid #000;
  background-color: transparent;
  .input-group {
    box-shadow: none;

    textarea {
      font-family: 'DM Sans';
      font-weight: 400;
      font-size: 15px;
      line-height: 26px;
      letter-spacing: 0.46px;
      background-color: transparent;
      color: black;
      width: 570px;

      @media screen and (max-width: 768px) {
        width: 285px !important;
      }

      @media screen and (max-width: 500px) {
        width: 200px !important;
      }

      &::placeholder {
        font-family: 'DM Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        text-transform: uppercase;
        color: black;
        line-height: 26px;
        letter-spacing: 0.46px;
      }
    }

    svg {
      fill: black;
    }

    // hides the button with a slash inside the search box
    &
      > div:first-of-type
      > div:first-of-type
      > div:first-of-type
      > div:first-of-type {
      display: none;
    }
  }
}

.dashboard_reactive_list.cp {
  position: relative;
  .list_resultsInfo {
    width: fit-content;
    position: absolute;
    top: -70px;
    left: 694px;
    transition: 0.3s ease-in-out;
    select {
      height: 50px;
      font-family: 'DM Sans';
      font-size: 15px;
      line-height: 26px;
      letter-spacing: 0.46px;
      padding-left: 15px;
      min-width: 200px;
      color: black;
    }
    &.hide {
      left: 674px;
      z-index: -1;
    }
    &.cpstore {
      top: -50px;
      left: 624px;
      &.hide {
        left: 604px;
        z-index: -1;
      }
    }
  }
}

.dashboard_reactive_list.realm {
  position: relative;
  width: 100% !important;

  & .list_pagination {
    margin: 0;
    padding-bottom: 48px !important;

    @media screen and (max-width: 500px) {
      padding-top: 24px !important;
    }
    a {
      margin: 0 5px;
      color: $dark-brown;
      background-color: $dim-brown;
      font-family: 'DM Sans';
      font-weight: 400;
      font-size: 15px;
      line-height: 26px;
      &.active {
        color: $white;
        background-color: $realm-green;
      }
      &:focus {
        border-color: none;
        box-shadow: 0 0 2px transparent;
      }
    }
  }

  .list_noResults {
    padding-top: 40px;
    padding-left: 340px;
    color: $dark-brown;
    font-family: 'Passero One', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
  .list_resultsInfo {
    width: fit-content;
    position: absolute;
    top: -50px;
    left: 788px;
    transition: 0.3s ease-in-out;
    select {
      height: 50px;
      font-family: 'DM Sans';
      font-size: 15px;
      line-height: 26px;
      letter-spacing: 0.46px;
      padding-left: 15px;
      min-width: 200px;
      color: black;
      cursor: pointer;
    }
    &.hide {
      left: 674px;
      z-index: -1;
    }
    &.cpstore {
      top: -50px;
      left: 624px;
      &.hide {
        left: 604px;
        z-index: -1;
      }
    }
  }
}
