@use '/src/assets/sass/index.scss' as * ;

.text-accordian-header {
    font: 'Passero One';
    font-size: '20px';
    font-style: 'normal';
    font-weight: 400;
    line-height: '26px';
    letter-spacing: '0.46px';
    text-transform: 'uppercase';
    color: '#000';
    padding: '10px';
    margin: '0px';
    zIndex: 3;
}

.text-accordian {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
    background-color: $realm-green;
    color: #FFF;
    border-radius: 4px;
    margin-bottom: 8px;
    transition: background-color 0.3s ease;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border: 0.5px solid;
    box-sizing: border-box;
    column-gap: 0;
    z-index: 200;
    position: relative;
    top: 0;
    left: 0;
    transform: translateZ(120px);

    .text-accordian-header__icon {
        height: 24px;
        width: 24px;
        object-fit: contain;
    }

    .text-accordian-header__text {
        font-size: 16px;
        font-weight: 600;
    }

    &.active {
        background-color: $realm-green;
    }
}