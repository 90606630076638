@use '/src/assets/sass/index.scss' as *;

.nav {
  height: 48px;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 300;
  overflow-y: hidden;

  @include media('<=tablet') {
    height: 24px;
    padding-right: 0;
  }

  @include media('<=phone-tab') {
    width: 100vw;
  }

  &.light {
    background-color: $milky-brown;
  }

  &.dark {
    background-color: $light-grey;
  }

  .tab_button_wrapper {
    display: flex;
    column-gap: 0;
  }

  .realm_wrapper {
    display: flex;
    align-items: center;
    padding-right: 20px;
    column-gap: 16px;

    @include media('<=phone-tab') {
      column-gap: 0px;
      padding-right: 0px;
    }

    .userName {
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.1px;

      &.light {
        color: $dark-brown;
      }

      &.dark {
        color: white;
      }
    }

    .userImage {
      width: 35px;
      height: 35px;
      object-fit: cover;
      border-radius: 50%;
      object-position: center center;
    }

    .token_wrapper {
      background-color: white;
      border-radius: 8px;
      height: 25px;
      max-height: 25px;
      padding: 0 4px;
      display: flex;
      column-gap: 10px;
      align-items: center;

      p {
        color: $dark-brown;
        text-align: center;
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: 0.1px;
        margin-block-start: 0;
        margin-block-end: 0;
        margin: 0;
      }

      .addButton {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        border: none;
        background-color: transparent;
        outline: none;
        position: relative;
        cursor: pointer;
        margin-left: 4px;
      }
    }

    .themeButton {
      padding: 0;
      display: flex;
      align-items: center;
      margin: 0;
      border: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;

      svg {
        height: 40px;
        width: 40px;
        display: flex;
        margin-top: 8px;

        @include media('<=tablet') {
          height: 30px;
          width: 30px;
        }
      }

      &.light {
        svg {
          fill: $dark-brown;
        }
      }

      &.dark {
        svg {
          fill: white;
        }
      }
    }
  }
}
