@use '/src/assets/sass/index.scss' as *;

.expansionArrow {
  color: $white;

  &.cp {
    color: $dark-brown;
    font-size: 30px;
  }

  &.character {
    color: $dark-brown;
    font-size: 30px;
  }

  &.rotated {
    transform: rotate(-180deg);
  }
}

.expansionTitle {
  color: $white;
  font-family: 'Passero One' !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.1px;

  &.character {
    color: $dark-brown;
  }
}

.expansionTokenItemImage {
  width: 100px;
  height: 100px;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
}

.expansionTokenItemStar {
  position: absolute;
  bottom: 0;
  right: 0;
}

.tokenSetFormHeading {
  font-family: 'Passero One';
  font-size: 20px;
  font-weight: 400;
  color: $white;
}
