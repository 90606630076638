@use '/src/assets/sass/index.scss' as *;

.toolkit {
  display: flex;
  width: fit-content;
  height: 47px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 0.5px solid;
  box-sizing: border-box;
  column-gap: 0;
  z-index: 200;
  position: fixed;
  top: 80px;
  left: 16px;
  transform: translateZ(120px);

  @include media('<=phone-tab') {
    top: 70px;
  }

  .toolkit_button {
    height: 100%;
    width: 56px;
    padding: 12px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;

    .toolkit_menu_icon {
      height: 24px;
      width: 24px;
      object-fit: contain;
    }
  }

  .toolkit_divider_wrapper {
    display: flex;
    width: 1px;
    height: 47px;
    padding: 0px;
    align-items: center;

    &::before {
      content: ' ';
      width: 1px;
      height: 15px;
    }
  }

  &.light {
    background-color: $toolbar-background-light;
    // background-color: $realm-green;
    border-color: $toolbar-divider-light;

    .toolkit_divider_wrapper {
      &::before {
        background-color: $toolbar-divider-light;
      }
    }
  }

  &.dark {
    background-color: $toolbar-background-dark;
    border-color: black;

    .toolkit_divider_wrapper {
      &::before {
        background-color: $toolbar-divider-dark;
      }
    }
  }
}

.heading {
  font-family: 'Passero One' !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 100% */
  letter-spacing: 0.1px;
  padding-right: 12px;

  &.light {
    color: $dark-brown;
  }

  &.dark {
    color: $white;
  }
}

.headingIcon {
  height: 50px;
  width: auto;

  &.light {
    .cls-2 {
      fill: $dark-brown !important;
    }
  }

  &.dark {
    .cls-2 {
      fill: $white !important;
    }
  }
}

.headingButton {
  border-radius: 5px;
  background: $realm-green;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: block;
  border: none;
  outline: none;
  padding: 7px 10px;
  cursor: pointer;
  @include media("<=tablet"){
    // width: calc(100vw - 40px)!important;
  }

  &.ai-gen {
    background: $realm-ai;
  }

  .headingButtonTitle {
    color: white;
    font-family: 'Passero One';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.my_custom_searchbox.cpp {
  border-bottom: 1px solid #000;
  background-color: transparent;
  .input-group {
    box-shadow: none;

    textarea {
      font-family: 'DM Sans';
      font-weight: 400;
      font-size: 15px;
      line-height: 26px;
      letter-spacing: 0.46px;
      background-color: transparent;
      color: black;
      width: 390px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    svg {
      fill: black;
    }

    // hides the button with a slash inside the search box
    &
      > div:first-of-type
      > div:first-of-type
      > div:first-of-type
      > div:first-of-type {
      display: none;
    }
  }
}

.dashboard_reactive_list.cpp {
  margin-top: 20px;
  .list_resultsInfo {
    display: none;
    select {
      height: 30px;
      font-family: 'DM Sans';
      font-size: 15px;
      line-height: 26px;
      letter-spacing: 0.46px;
      padding-left: 15px;
      min-width: 100%;
      color: black;
    }
  }
}
