@use '/src/assets/sass/index.scss' as * ;

.panelCard___item{
    p{
        font-family: 'Passero One' !important;
        font-size: 1.5rem;
    }

    img{
        transition: $standard-transition;
    }

    &:hover img, &.active img{
        filter: $toolbar-hover-glow;
    }
}