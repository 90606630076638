@use '/src/assets/sass/index.scss' as *;

.searchBox {
  border-bottom: 1px solid #fff !important;

  & .MuiInputBase-root.MuiInput-root {
    input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch {
      color: #fff !important;
      font-family: 'DM Sans' !important;
      font-weight: 400 !important;
      font-size: 15px !important;
      line-height: 26px !important;
      letter-spacing: 0.46px !important;
    }

    &::after,
    &::before {
      border: none !important;
      outline: none !important;
    }
  }
}

.my_custom_searchbox {
  border-bottom: 1px solid #fff;
  background-color: transparent;
  .input-group {
    box-shadow: none;

    textarea {
      font-family: 'DM Sans';
      font-weight: 400;
      font-size: 15px;
      line-height: 26px;
      letter-spacing: 0.46px;
      background-color: transparent;
      color: $white;
    }

    svg {
      fill: $white;
    }

    // hides the button with a slash inside the search box
    &
      > div:first-of-type
      > div:first-of-type
      > div:first-of-type
      > div:first-of-type {
      display: none;
    }
  }
}

.my_custom_reactive_list {
  width: 100% !important;
  // hides the results box of the reactive search list
  & .list_resultsInfo,
  & .list_resultStats {
    display: none;
  }
  & .list_pagination {
    a {
      padding: 5;
      margin: 0;
      color: white;
      background-color: transparent;
      font-family: 'DM Sans';
      font-weight: 400;
      font-size: 15px;
      line-height: 26px;
      &.active {
        color: $orange;
      }
      &:focus {
        border-color: #ff6846af;
        box-shadow: 0 0 2px #ff6846af;
      }
    }
  }
}

.fixedFilterWrapper {
  width: 100%;
  padding: 5px 11px;
  box-sizing: border-box;
  // display: flex;
  // flex-direction: column;
  row-gap: 8px;

  &.light {
    background-color: $toolbar-background-light;
    border-top: 1px solid #5d5d5d;
  }
  &.dark {
    border-top: 1px solid black;
    background-color: $toolbar-background-dark;
  }
}

.loadMore {
  cursor: pointer;
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 15px;
  color: $white;
  margin-left: 1rem;
}

.loadMore:hover {
  color: $orange;
}
