@use '../sass/main.scss' as *;

.spinnerMini {
  width: 20px;
  height: 20px;
  animation: rotate 1.5s infinite linear;
  color: $white-2;
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}
