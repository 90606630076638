@use '../sass/main.scss' as *;

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $milky-brown;

  &__main {
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: calc(100% - 48px);
  }
}
