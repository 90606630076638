// Color System
$dim-brown: #A89F68 !default;
$milky-brown: #D0B17A !default;
$dark-brown: #250503 !default;
$realm-green: #41521F !default;
$off-white: #E9E9E9 !default;
$light-grey:#5D5D5D !default;
$dark-grey:#313131 !default;
$white: #FFFFFF !default;
$orange: #FF6746 !default;
// $realm-ai:#7F3168 !default; changed for accessibility sake
$realm-ai:#DA16A2 !default;
$realm-ai-disabled: #5f2e51 !default;
$realm-ai-disabled-text: #86627c !default;
$upload-button-color: #5D865C !default;
$upload-button-color-light: #206a1f !default;

$toolbar-hover-glow: drop-shadow(0 0 2px #ffffffb3) drop-shadow(0 0 5px #ffffffb3);
$toolbar-background-dark: #141218 !default; 
$toolbar-divider-dark: #49454F !default;
$toolbar-background-light: $dark-grey !default; 
$toolbar-divider-light: $dark-brown !default;

$toolbar-icon-thickness:2;

$panel-tab-unselected-color: rgba(255, 255, 255, 0.50) !default;
$panel-tab-width: 353px !default;
$panel-tab-height: 450px !default;
$panel-tab-height-720: 650px !default;  

$standard-transition: .3s ease-in-out;


$colors:();
$colors: map-merge((
    "dim-brown": $dim-brown,
    "milky-brown":$milky-brown,
    "dark-brown":$dark-brown,
    "realm-green":$realm-green,
    "off-white":$off-white,
    "light-grey":$light-grey,
    "dark-grey":$dark-grey,
    "white":$white,
), $colors)



