@use "/src/assets/sass/index.scss" as *;

.panel {
  &__list {
    border-radius: 4px;
  }
}

.MuiListItemButton-root {
  background-color: transparent !important;
}

.MuiListItemButton-root:hover .panel__list__icon img {
  cursor: pointer;
  filter: drop-shadow(0px 0px 4px #fff) drop-shadow(0px 0px 4px #fff);
}

.panel__list__icon img {
  width: 26px !important;
  height: auto !important;
}

.toolkit {
  display: flex;
  width: fit-content;
  height: 47px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 0.5px solid;
  box-sizing: border-box;
  column-gap: 0;
  z-index: 200;
  position: fixed;
  top: 80px;
  left: 16px;
  transform: translateZ(120px);

  @include media("<=phone-tab") {
    top: 40px;
  }

  .toolkit_button {
    height: 100%;
    width: 56px;
    padding: 12px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;

    .toolkit_menu_icon {
      height: 24px;
      width: 24px;
      object-fit: contain;
    }
  }

  .toolkit_divider_wrapper {
    display: flex;
    width: 1px;
    height: 47px;
    padding: 0px;
    align-items: center;

    span {
      width: 1px;
      height: 15px;
    }
  }

  &.light {
    background-color: $toolbar-background-light;
    border-color: $toolbar-divider-light;

    .toolkit_divider_wrapper {
      span {
        background-color: $toolbar-divider-light;
      }
    }
  }

  &.dark {
    background-color: $toolbar-background-dark;
    border-color: black;

    .toolkit_divider_wrapper {
      span {
        background-color: $toolbar-divider-dark;
      }
    }
  }
}

.panel_collapse {
  height: 600px;
  position: fixed;
  z-index: 101;
  left: 16px;
  top: 130px;
}

.menu {
  position: fixed;
  left: 16px;
  top: 148px;
  z-index: 102;
  width: fit-content;
  transition: $standard-transition;
}

@media screen and (max-width: 767px) {
  .toolkit {
    bottom: 0;
    top: auto;
    left: 0;
    border-radius: 0;
    height: 89px;
    overflow-x: scroll !important;
    width: 100%;
    padding-inline: 29px;
    column-gap: 20px;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      display: none;
    }

    .toolkit_divider_wrapper {
      display: none;
    }
  }
}
