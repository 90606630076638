@use '/src/assets/sass/index.scss' as * ;

.toolbar-class{
  height: 30px;
  display: flex;
  align-items: center;
  gap: 15px;
  // box-sizing: border-box;
  border-bottom: 1px solid #BCAD93;
  border-right: 1px solid #BCAD93;
  background: #F7F2E9;
  padding: 10px 20px 10px 30px;
  .editorWrapper{
    display: flex;
    gap: 15px;
    .editorImg{
      width: fit-content;
      display: flex;
      align-items: center;
      img{
        width: auto;
        height: 15px;
        object-fit: contain;
        cursor: pointer;
      }
    }
  }
}

.editor-class{
  border-bottom: 1px solid #BCAD93;
  border-right: 1px solid #BCAD93;
  background: #F7F2E9;
  padding: 20px 20px 20px 30px;
}
