@use '/src/assets/sass/index.scss' as * ;

.cards_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 520px;
    flex-wrap: wrap;
    gap: 20px;
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 250;

    .loading_gif{
        width: 100%;
        height: auto;
    }
}

.character_type_menu_wrapper{
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 250;
}

@media screen and (max-height: 900px) {
    .character_type_menu_wrapper{
        bottom: 20px;
    }

    .empty_cards_wrapper{
        width: 420px;
    }
}

@media screen and (max-width: 500px) {
    .cards_wrapper{
        z-index: 200;
        width: 80%;
        display: none;
    }
}