@use '/src/assets/sass/index.scss' as *;

.floating_menu {
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  width: fit-content;
  padding: 8px 0;

  &.floating_menu_fullwidth {
    width: 100% !important;
  }

  .floating_menu_item_history {
    display: flex;
    height: 56px;
    padding: 8px 12px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    justify-content: space-between;
    transition: $standard-transition;
    background-color: transparent;
    color: $white;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer !important;
    font-family: 'Roboto';
    font-size: 16px;

    &.light {
      &:hover,
      &.active {
        background-color: transparent !important ;
      }
    }
    &.dark {
      &:hover,
      &.active {
        background-color: transparent !important ;
      }
    }
  }

  .floating_menu_item {
    display: flex;
    height: 56px;
    padding: 8px 12px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    justify-content: space-between;
    transition: $standard-transition;
    background-color: transparent;
    color: $white;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer !important;
    font-family: 'Roboto';
    font-size: 16px;

    &.light {
      &:hover,
      &.active {
        background-color: $milky-brown !important ;
        color: $dark-brown;
      }
    }
    &.dark {
      &:hover,
      &.active {
        background-color: $light-grey !important ;
      }
    }
  }

  &.light {
    background-color: $toolbar-background-light;
  }

  &.dark {
    background-color: $toolbar-background-dark;
  }
}

@media screen and (max-width: 865px) {
  .floating_menu {
    position: absolute;
    top: 150px;
  }
}

// @media screen and (max-width: 500px) {
//   .floating_menu {
//     position: absolute;
//     top: calc(100vh - 420px);
//     left: -20px;
//     // bottom: 89px;
//   }
// }
