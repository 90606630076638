// *,
// *::before,
// *::after {
//   box-sizing: border-box;
//   padding: 0;
//   margin: 0;

//   /* Creating animations for dark mode */
//   transition: background-color 0.3s, border 0.3s;
// }

// html {
//   font-size: 62.5%;
// }

// body {
//   transition: color 0.3s, background-color 0.3s;
//   min-height: 100vh;
//   line-height: 1.5;
//   font-size: 1.6rem;

//   overflow: hidden;
// }

// input,
// button,
// textarea,
// select {
//   font: inherit;
//   color: inherit;
// }

button {
  cursor: pointer;
}

// *:disabled {
//   cursor: not-allowed;
// }

// // select:disabled,
// // input:disabled {
// //   background-color: var(--color-grey-200);
// //   color: var(--color-grey-500);
// // }

// // input:focus,
// // button:focus,
// // textarea:focus,
// // select:focus {
// //   outline: 2px solid var(--color-brand-600);
// //   outline-offset: -1px;
// // }

// button:has(svg) {
//   line-height: 0;
// }

// a {
//   color: inherit;
//   text-decoration: none;
// }

// ul {
//   list-style: none;
//   margin: 0 !important;
// }

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}

// img {
//   max-width: 100%;

//   /* For dark mode */
//   // filter: grayscale(var(--image-grayscale)) opacity(var(--image-opacity));
// }

// ::-webkit-scrollbar {
//   width: 6px;
// }

// ::-webkit-scrollbar-track {
//   background: #d0b17a;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: gray;
//   border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background-color: gray;
// }
