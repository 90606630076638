@use '../sass/main.scss' as *;

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 228px;
  background-image: url('../../assets/images/NewDashboard/brown_bg.v1.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  margin-top: 22px;
  // margin-bottom: -30px;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
  transform: translateX(-100%);

  @media (min-width: 768px) {
    position: static;
    transform: none;
    width: 228px;
    height: 100vh;
    margin-top: -5px;
  }
  z-index: 2;

  &.expanded {
    transform: translateX(0);
  }

  &__box {
    padding-top: 33px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 163px;
    margin: 0 auto;

    &__logo {
      width: 70px;
      height: 80.255px;
    }

    &__logoText {
      color: $white-2;
      font-family: 'Passero One';
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__options {
    margin-top: 52.85px;
    padding-left: 13px;
    padding-right: 13px;
  }

  &__optionsBox {
    @include flex(flex-start, center, 4px);
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: 1px solid $dark-brown;
    padding-left: 24px;
    transition: all 0.3s ease-in;
    cursor: pointer;

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 49px;
      height: 49px;
    }

    &__image {
      width: 31px;
      height: 31px;
    }

    &__imageLg {
      width: 49px;
      height: 49px;
    }

    &__text {
      color: $white-2;
      font-family: 'Passero One';
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }

  &__footer {
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 103px;

    &__text {
      color: $white-2;
      font-family: 'DM Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      height: 21px;
      padding-bottom: 9px;
    }

    &__recognition {
      color: $white-2;
      font-family: 'DM Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }

    &__link {
      color: #e9dcc3;
      font-family: 'DM Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
    }
  }
}

.menuButton {
  position: absolute;
  top: toRem(20);
  left: toRem(7);
  z-index: 3;
  color: $white-2;
  cursor: pointer;

  @media (min-width: 768px) {
    display: none;
  }
}
