@use '/src/assets/sass/index.scss' as * ;

.characterFormWrapper{
    padding: 28px 40px;
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;

    &.light{
        background-color: $toolbar-background-light;
        
        .characterTitle{
            background-color: $toolbar-background-light;
        }

        .characterItemsWrapper{
               /* Firefox*/
            &.scrollbar {
            scrollbar-width: thin; 
            scrollbar-color: $toolbar-background-light transparent !important;
            }
            
            &::-webkit-scrollbar-thumb, &.scrollbar-thumb {
            background-color: $milky-brown !important;
            border-radius: 5px;
            padding-top: 10px;
            } 
            &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover {
            background-color: $toolbar-divider-light  !important;
            }
        }
    }
    &.dark{
        background-color: #36343B;
        // background-color: $toolbar-background-dark;
        .characterTitle{
            background-color: #36343B;
        }
        .characterItemsWrapper{
            /* Firefox*/
         &.scrollbar {
         scrollbar-width: thin; 
         scrollbar-color: $toolbar-background-dark transparent !important;
         }
         
         &::-webkit-scrollbar-thumb, &.scrollbar-thumb {
         background-color: $toolbar-divider-dark !important;
         border-radius: 5px;
         padding-top: 10px;
         } 
         &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover {
         background-color: $light-grey !important;
         }
     }
    }

    .characterItemsWrapper{
        display: flex;
        align-items: center; 
        gap: 10px;
        max-width: 700px;
        overflow-x: scroll;
        height: 220px;

        &::-webkit-scrollbar{
          height: 5px;
        }
    }

    .characterItemWrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        min-width: 140px;
        
        // &:hover, &.active{
        //     filter: $toolbar-hover-glow;
        // }

        .characterImageContainer{
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 18px;
            width: 100px;
            cursor: pointer;
            // transition: $standard-transition;
            justify-content: center;
            position: relative;
            margin-bottom: 30px;

            img{
                height: 90px;
                width: 90px;
            }
    

            &::after{
                content: "";
                display: inline-block;
                margin: 12px auto 0 auto!important;
                width: 20px!important;
                height: 20px!important;
                border: 2px solid #49454f!important;
                border-radius: 50%;
                background-color: transparent;
                left: 50%;
                bottom: -36px;
                transform: translateX(-50%);
                position: absolute;
            }
            
            .characterTitle{
                font-family: "Roboto";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
                letter-spacing: 0.1px;
                color: $white;
                text-wrap: nowrap;
                padding: 6px 16px;
                box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
                border-radius: 8px;
                width: fit-content;
                display: block;
            }
        }

        &.active{
            .characterImageContainer{
                &::after{
                    border-color: $realm-green!important;
                }
                &::before{
                    content: "";
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: $realm-green;
                    left: 50%;
                    bottom: -29px;
                    transform: translateX(-50%);
                    position: absolute;
                }
            }
        }

        input[type="radio"]{
            display: none;
        }
    }
}

@media screen and (max-height: 900px) {
    .characterImageContainer{
        img{
            height: 70px!important;
            width: 70px!important;
        }
    }
}

@media screen and (max-width: 500px){
    .characterFormWrapper{
        padding: 10px;
        .characterItemsWrapper{
            display: flex;
            align-items: center; 
            gap: 0px!important;
            max-width: 90vw!important;
            overflow-x: scroll;
            height: 120px!important;

            .characterItemWrapper{
                min-width: 80px!important;
                .characterImageContainer{
                    // display: flex;
                    // flex-direction: column;
                    // align-items: center;
                    row-gap: 8px!important;
                    width: 60px!important;
                    // cursor: pointer;
                    // // transition: $standard-transition;
                    // justify-content: center;
                    // position: relative;
                    margin-bottom: 10px!important;
            
                    img{
                        height: 40px!important;
                        width: 40px!important;
                    }
            
            
                    &::after{
                        content: "";
                        display: inline-block;
                        margin: 12px auto 0 auto!important;
                        width: 10px!important;
                        height: 10px!important;
                        border: 2px solid #49454f!important;
                        border-radius: 50%;
                        background-color: transparent;
                        left: 50%;
                        bottom: -15px;
                        transform: translateX(-50%);
                        position: absolute;
                    }
                    
                    .characterTitle{
                        font-family: "Roboto";
                        font-size: 10px!important;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 14px!important; /* 142.857% */
                        letter-spacing: 0.1px;
                        color: $white;
                        text-wrap: nowrap;
                        padding: 3px 8px!important;
                        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
                        border-radius: 8px;
                        width: fit-content;
                        display: block;
                    }
                }
    
                &.active{
                    .characterImageContainer{
                        &::after{
                            border-color: $realm-green!important;
                        }
                        &::before{
                            content: "";
                            display: inline-block;
                            width: 5px!important;
                            height: 5px!important;
                            border-radius: 50%;
                            background-color: $realm-green;
                            left: 50%;
                            bottom: -11px!important;
                            transform: translateX(-50%);
                            position: absolute;
                        }
                    }
                }
            }
            }
            
    }
}