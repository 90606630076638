@use '../sass/main.scss' as *;

.modal {
  width: 829px;
  height: 500px;

  border-radius: 20px;
  border: 1px solid #e0e0e0;
  background: $white-2;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  &__top {
    height: 50px;
    background: $realm-green;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 44px;

    & svg {
      width: 22px;
      height: 22px;
      color: $white-2;
      padding-left: 29px;
      cursor: pointer;
    }

    &__heading {
      color: $white-2;
      font-family: 'Passero One';
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.46px;
      text-transform: uppercase;
    }
  }

  .container {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    width: 100%;
    height: 100%;

    .box {
      width: 466px;
      margin: 0 auto;
      margin-top: 58px;

      &__payment {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;

        &__card {
          width: 16px;
          height: 16px;
          color: $dark-brown;
        }

        &__cardSelected {
          color: #a89f68;
        }

        &__paypal {
          width: 20px;
          height: 20px;
          color: $dark-brown;
        }

        &__paypalSelected {
          color: #a89f68;
        }

        &__apple {
          width: 30px;
          height: 30px;
          color: $dark-brown;
        }

        &__appleSelected {
          color: #a89f68;
        }

        &__dots {
          color: $dark-brown;
        }

        &__textCard {
          color: $dark-brown;
          font-family: 'DM Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        &__textCardSelected {
          color: #a89f68;
        }

        &__textPaypal {
          color: $dark-brown;
          font-family: 'DM Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        &__textPaypalSelected {
          color: #a89f68;
        }

        &__textApple {
          color: $dark-brown;
          font-family: 'DM Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        &__textAppleSelected {
          color: #a89f68;
        }

        &__wrapper {
          width: 127px;
          height: 64px;
          box-sizing: border-box;
          display: flex;
          padding: 10px 12px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          border-radius: 6px;
          border: 2px solid #e0e0e0;
          background: $white-2;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
          cursor: pointer;
        }

        &__wrapperSelected {
          border: 2px solid #a89f68;
        }

        &__wrapperSmall {
          width: 40px;
          height: 64px;
          box-sizing: border-box;
          display: flex;
          padding: 10px 12px;
          padding-top: 3px;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          border-radius: 6px;
          border: 2px solid #e0e0e0;
          background: $white-2;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
        }
      }

      .form {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 466px;

        &__boxLg {
          display: flex;
          flex-direction: column;
          gap: 4px;
          position: relative;

          &__label {
            color: $dark-brown;
            font-family: 'DM Sans';
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          &__input {
            width: 100%;
            box-sizing: border-box;
            height: 43px;

            border-radius: 6px;
            border: none;
            border: 2px solid #e0e0e0;
            background: #fff;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
            outline: none;
            padding: 10px 12px;

            color: #a5acb8;
            font-family: 'DM Sans';
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          &__input::placeholder {
            color: #a5acb8;
            font-family: 'DM Sans';
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          &__platforms {
            position: absolute;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 4px;
            left: 338px;
            top: 33.5px;

            &__image {
              width: 23.75px;
              height: 16px;
              border-radius: 4px;
              border: 0.5px solid #ada392;
            }
          }
        }

        &__container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
        }

        &__boxSm {
          display: flex;
          flex-direction: column;
          gap: 4px;

          &__label {
            color: $dark-brown;
            font-family: 'DM Sans';
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          &__input {
            width: 228px;
            box-sizing: border-box;
            height: 43px;

            border-radius: 6px;
            border: none;
            border: 2px solid #e0e0e0;
            background: #fff;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
            outline: none;
            padding: 10px 12px;

            color: #a5acb8;
            font-family: 'DM Sans';
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          &__input::placeholder {
            color: #a5acb8;
            font-family: 'DM Sans';
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          &__select {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 228px;
            box-sizing: border-box;
            height: 43px;

            border-radius: 6px;
            border: none;
            border: 2px solid #e0e0e0;
            background: #fff;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
            outline: none;
            padding: 10px 12px;

            &__name {
              color: #000;
              font-family: 'DM Sans';
              font-size: 13px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            & svg {
              width: 17px;
              height: 17px;
            }
          }
        }

        &__button {
          align-self: end;
          display: block;
          padding: 4px 10px;
          border-radius: 4px;
          background: $realm-green;
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);

          border: none;
          outline: none;
          width: 73px;

          color: $white-2;
          font-family: 'Passero One';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.46px;
          text-transform: uppercase;
        }

        &__nullSection {
          padding-top: 20px;
          color: $dark-brown;
          font-family: 'DM Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
}
