@use '../sass/main.scss' as *;

.layout {
  display: flex;
  flex-direction: column;
  gap: 0;

  // height: 100vh;

  &__background {
    background-image: url('../../assets/images/NewDashboard/green_banner.png');
    background-size: cover;
    // background-position: center;
    background-repeat: no-repeat;
    background-color: $milky-brown;
    height: 200px;
    width: 100vw;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    &__gradient {
      position: absolute;
      bottom: 0;
      height: 195px;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(207, 174, 121, 0) 0%,
        #cfae79 100%
      );
      z-index: 2;
    }

    &__logo {
      height: 75px;
      width: 75px;
      position: absolute;
      top: 20px;
      left: 36px;
      z-index: 5;

      @include media('<=phone-tab') {
        height: 40px;
        width: 40px;
        top: 20px;
        left: 20px;
      }
    }

    &__text {
      color: #fff;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: 'Passero One', sans-serif;
      font-size: 100px; /* Replaced toRem(100) with 100px */
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      z-index: 3;

      @include media('<=tablet') {
        font-size: 50px;
      }

      @include media('<=phone-tab') {
        font-size: 40px;
      }
    }
  }

  &__main {
    background-color: $milky-brown;
    // height: toRem(1190);
    position: relative;
    // background: url('../../assets/images/NewDashboard/wallpaper_light.png');
    background-color: #d0b17a;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;

    &__gradient {
      position: absolute;
      top: 0;
      height: 264px; /* Replaced toRem(264) with 264px */
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(207, 174, 121, 1) 0%,
        rgba(207, 174, 121, 0) 100%
      );
      z-index: 1;
    }

    &__header {
      @include flex(flex-start, center, 13px);
      padding-left: 92px;

      @include media('<=tablet') {
        padding-left: 0px;
        gap: 5px;
      }
    }

    &__image {
      width: 50px; /* Replaced toRem(50) with 50px */
      height: 50px; /* Replaced toRem(50) with 50px */
      z-index: 3;
    }

    &__text {
      color: $dark-brown;
      font-family: 'Passero One', sans-serif;
      font-size: 20px; /* Replaced toRem(20) with 20px */
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1px;
      z-index: 3;
    }

    &__buttonWrapper {
      @include flex(center, center, 26px);
      padding-left: 106px;
      padding-right: 103px;
      padding-top: 32px;

      @include media('<=tablet') {
        flex-direction: column;
        padding-left: 0px;
        padding-right: 0px;
      }
    }

    &__container {
      @include flex(center, center, 0);

      @include media('<=tablet') {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding-right: 20px;
        padding-left: 20px;
      }
    }

    &__button {
      @include flex(flex-start, center, 4px);
      padding-top: 7px;
      padding-bottom: 7px;
      padding-left: 10px;
      width: 150px;
      height: 40px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 5px; /* Replaced toRem(5) with 5px */
      background-color: $realm-green;
      outline: none;
      border: none;

      @include media('<=phone-tab') {
        padding-left: 7px;
        padding-right: 0;
        width: 140px;
      }
    }

    &__buttonAdd {
      width: 8px;
      height: 10px;
    }

    &__buttonRealm {
      width: 25px;
      height: 25px;
    }

    &__buttonText {
      color: $white-2;
      font-family: 'Passero One', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__actions {
      display: flex;
      justify-content: space-between;
      padding-right: 103px;

      @include media('<=tablet') {
        padding-right: 0px;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    &__actionsSearch {
      @include flex(
        flex-start,
        center,
        38px
      ); /* Replaced toRem(38) with 38px */
    }

    &__actionsView {
      margin-left: 14px;
      @include flex(flex-start, center, 14px);

      @include media('<=phone-tab') {
        gap: 10px;
      }
    }

    &__actionsButton {
      @include flex(flex-start, center, 0);
      padding: 10px;
      width: 50px;
      height: 50px;
      border-radius: 4px;
      background-color: $realm-green;
      outline: none;
      border: none;

      @include media('<=phone-tab') {
        width: 35px;
        height: 35px;
      }
    }

    &__actionsButton.active {
      @include flex(flex-start, center, 0);
      padding: 10px;
      width: 50px;
      height: 50px;
      border-radius: 4px;
      background-color: $light-brown;
      outline: none;
      border: none;

      @include media('<=phone-tab') {
        width: 35px;
        height: 35px;
      }
    }

    &__actionsIcon {
      width: 30px; /* Replaced toRem(30) with 30px */
      height: 30px; /* Replaced toRem(30) with 30px */
      transition: all 0.3s ease-in-out;

      @include media('<=phone-tab') {
        width: 15px;
        height: 15px;
      }

      &:hover {
        filter: drop-shadow(0 0 2px #ffffffb3) drop-shadow(0 0 5px #ffffffb3);
      }
    }

    // &__actionsIcon.active {
    //   filter: drop-shadow(0 0 2px #ffffffb3) drop-shadow(0 0 5px #ffffffb3);
    // }
  }
}

.token_wrapper {
  background-color: $white;
  border-radius: 8px;
  height: 25px;
  max-height: 25px;
  padding: 0 4px;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  z-index: 5;
  position: absolute;
  top: 30px;
  right: 20px;
  display: none;

  @include media('<=phone-tab') {
    gap: 2px;
    display: flex;
  }

  p {
    color: $dark-brown;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 0;

    @include media('<=tablet') {
      font-size: 10px;
    }
  }

  .addButton {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    outline: none;
    position: relative;
    cursor: pointer;
    margin-left: 4px;
  }
}
