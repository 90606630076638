@use '/src/assets/sass/index.scss' as * ;

.rwcolorpicker_wrapper{
    padding: 0 11px;
    
    .rwcolorpicker_title{
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: .1px;
        padding: 6px 14px;
        margin-top: 11px;
        
        &.light{
            color: $white;
        }
        
        &.dark{
            color: $white;
        }

        &.character{
            color: $dark-brown;
        }
    }

    .rwcolorpicker_form_parent_wrapper{
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        padding: 15px 0;

        .rwcolorpicker_form_wrapper{
            display: flex;
            column-gap: 0px;
            row-gap: 40px;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 75%;

            .rwcolorpicker_input_wrapper{
                width: 48%;

                .rwcolorpicker_input_title{
                    font-family: DM Sans;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 166.667% */
                    letter-spacing: 0.1px;
                    color:$white;
                }

                .rwcolorpicker_input{
                    border-radius: 1px!important;
                    border: solid 1px !important;
                    border-color: $white!important;
                    color: $white!important;
                    padding: 6px!important;
                    height: 32px!important;
                    box-sizing: border-box!important;
                    font-size: 14px!important;
                    font-family: DM Sans!important;
                    font-weight: 500!important;
                    text-align: center!important;
                    width: 100% !important;

                    &::after{
                        outline: none!important;
                        border: none!important;
                    }
                }
            }
        }

        .rwcolorpicker_colorbox_wrapper{
            padding-left: 40px;
            .rwcolorpicker_colorbox{
                width: 63px;
                height: 145px;
            }
        }

        &.character{
            .rwcolorpicker_form_wrapper{
                display: flex;
                column-gap: 0px;
                row-gap: 10px;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 75%;
    
                .rwcolorpicker_input_wrapper{
                    width: 48%;
    
                    .rwcolorpicker_input_title{
                        font-size: 14px;
                        color:black;
                    }
    
                    .rwcolorpicker_input{
                        border-color: black!important;
                        color: black!important;
                        font-size: 14px!important;
    
                        &::after{
                            outline: none!important;
                            border: none!important;
                        }
                    }
                }
            }
    
            .rwcolorpicker_colorbox_wrapper{
                padding-left: 20px;
                .rwcolorpicker_colorbox{
                    width: 63px;
                    height: 122px;
                }
            }
        }

        // .rwcolorpicker_alpha_wrapper{
        //     width: 25%;
        //     position: relative;

        //     .rwcolorpicker_alpha{
        //         transform: rotate(90deg);
        //         transform-origin: center;
        //         height: 63px;
        //         width: 145px;
        //         position: absolute!important;
        //         left: -30px!important;
        //         bottom: 41px!important;
        //     }
        // }


    }

    .rwcolorpicker_saturation{
        width: 100%;
        height: 330px;
        position: relative;
        
        &.character{
            height: 192px!important;
        }
    }

    .rwcolorpicker_hue{
        width:100%;
        height: 32px;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
