@function toRem($value) {
  $remValue: (calc($value / 10)) + rem;
  @return $remValue;
}

@mixin textCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@mixin flex($jc, $ai, $gap) {
  display: flex;
  justify-content: $jc;
  align-items: $ai;
  gap: $gap;
}

@mixin flex_col($gap) {
  flex-direction: column;
  display: flex;
  gap: $gap;
}

@mixin header_div($width, $height, $br) {
  width: $width;
  height: $height;
  border-radius: $br;
  cursor: pointer;
}

@mixin button($width, $br) {
  width: $width;
  border-radius: $br;
  cursor: pointer;
}
