@use '../sass/main.scss' as *;

.toggleSlider {
  position: relative;
  width: 100px;
  height: 39px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
  border-radius: 20px;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white-2;
  border-radius: 34px;
  cursor: pointer;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  width: 45px;
  height: 35px;
  left: 2.2px;
  bottom: 2.2px;
  background-color: #c89a4a;
  border-radius: 25px;
  transition: 0.4s;
}

.toggleSlider input[type='checkbox'] {
  display: none;
}

.toggleSlider input[type='checkbox']:checked + .slider:before {
  background-color: #c89a4a;
  border: none;
}

.toggleSlider input[type='checkbox']:checked + .slider {
  background-color: $white-2;
}

.toggleSlider input[type='checkbox']:checked + .slider:before {
  transform: translateX(51px);
}
