@use '../sass/main.scss' as *;

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}

.spinner {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 6.4rem;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, $dark-brown 94%, #0000) top/10px
      10px no-repeat,
    conic-gradient(#0000 30%, $dark-brown);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 10px), #000 0);
  animation: rotate 1.5s infinite linear;
  z-index: 15;

  &__hero {
    // position: absolute;
    // top: 0;
    margin-top: -750px;
    margin-left: auto;
    margin-right: auto;
    z-index: 15;
    margin-bottom: 550px;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__realm {
    margin: 212px auto;
    padding-top: 0;
    padding-bottom: 0;
  }
}
