@use '/src/assets/sass/index.scss' as * ;

.text_editor_parent_wrapper{
    padding: 33px 11px 0 11px;

    .text_editor_field{
        border-radius: 1px;
        border: none;
        outline: none;
        background: rgba(255, 255, 255, 0.50);
        padding: 10px 16px;
        width: 100%;
        box-sizing: border-box;
        resize: none;
        filter: none!important;
        box-shadow: none!important;
        color: $white!important;
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        letter-spacing: 0.1px;
    }

    .text_editor_autocomplete{
        background: rgba(255, 255, 255, 0.50);
        box-sizing: border-box;
        border-radius: 1px;
        padding-right: 16px;
    }

    .text_editor_size{
        background: rgba(255, 255, 255, 0.50);
        box-sizing: border-box;
        height: 40px;
        padding: 10px 16px;
        border-radius: 1px;
    }

    .text_style_button_wrapper{
        display: flex;
        justify-content: right;
        flex-direction: row;
        column-gap: 8px;

        .text_style_button{
            display: block;
            border: solid 1px $white;
            box-sizing: border-box;
            text-align: center;
            font-family: DM Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.1px;
            width: 42px;
            height: 40px;
            cursor: pointer;
            background-color: transparent;
            color: $white;
            transition: $standard-transition;

            &.bold{
                font-weight: bold!important;
            }

            &.italics{
                font-style: italic!important;
            }

            &.underline{
                text-decoration-line: underline!important;
            }

            &:hover{
                filter:$toolbar-hover-glow;
            }
            
            &.active{
                filter:$toolbar-hover-glow;
            }
        }
    }
}
