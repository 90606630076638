@use '/src/assets/sass/index.scss' as * ;

.dashboard_reactive_list{
    width: 100%!important;
    // hides the results box of the reactive search list
    // & .list_resultsInfo, & .list_resultStats{
    //     display: none;
    // }
    & .list_pagination{
        a {
            padding: 5;
            margin: 0 5px;
            color: $dark-brown;
            background-color: $dim-brown;
            font-family: "DM Sans";
            font-weight: 400;
            font-size: 15px;
            line-height: 26px;
            &.active{
                color: $white;
                background-color: $realm-green;
            }
            &:focus{
                border-color:none;
                box-shadow: 0 0 2px transparent;
            }
        }
    }
}