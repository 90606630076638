@use '../sass/main.scss' as *;

.realm_wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
  padding-right: 20px;

  @include media('<=tablet') {
    gap: 8px;
  }

  @include media('<=phone-tab') {
    padding-right: 10px;
  }

  .logo {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 50%;
    object-position: center center;
    cursor: pointer;

    @include media('<=tablet') {
      display: none;
    }
  }

  .userName {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    width: max-content;

    @include media('<=tablet') {
      font-size: 10px;
    }
  }

  .userImage {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 50%;
    object-position: center center;
    cursor: pointer;
  }

  .shield {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    object-position: center center;
    cursor: pointer;

    @include media('<=tablet') {
      display: none;
    }
  }

  .token_wrapper {
    background-color: $white;
    border-radius: 8px;
    height: 25px;
    max-height: 25px;
    padding: 0 4px;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    @include media('<=tablet') {
      gap: 2px;
    }

    @include media('<=phone-tab') {
      display: none;
    }

    p {
      color: $dark-brown;
      text-align: center;
      font-family: 'DM Sans', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 0;

      @include media('<=tablet') {
        font-size: 10px;
      }
    }

    .addButton {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      border: none;
      background-color: transparent;
      outline: none;
      position: relative;
      cursor: pointer;
      margin-left: 4px;
    }
  }
}
