@use '/src/assets/sass/index.scss' as *;

.tab_button {
  padding: 0;
  display: block;
  margin: 0;
  border: none;
  background-color: transparent;
  outline: none;
  position: relative;
  z-index: 2;
  cursor: pointer;
  box-sizing: border-box;

  @include media('<=tablet') {
    height: 24px;
    width: 80px;
  }

  &:not(:first-of-type) {
    margin-left: -18px;
    z-index: 3;

    @include media('<=tablet') {
      // he
      margin-left: 0px;
    }

    .button_name {
      margin-left: 11px;

      @include media('<=tablet') {
        margin-left: 5px;
      }
    }
  }

  .button_name {
    color: $off-white;
    z-index: 1;
    min-width: 160px;
    max-width: 160px;
    height: 48px;
    align-items: center;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    margin-left: 11px;
    font-family: 'Passero One';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    letter-spacing: 0.46px;
    text-transform: uppercase;

    @include media('<=tablet') {
      height: 24px;
      margin-left: 5.5px;
      font-size: 9px;
    }
  }

  svg {
    height: 48px;
    width: 160px;
    fill: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    @include media('<=tablet') {
      height: 24px;
      width: 80px;
    }

    path {
      fill: $realm-green;
    }
  }

  &:hover {
    border: none;
    background-color: transparent;
    outline: none;

    .button_name {
      color: $dark-brown;
    }

    svg {
      path {
        fill: $dim-brown;
      }
    }
  }

  &:focus {
    border: none;
    background-color: transparent;
    outline: none;
  }

  &.active {
    border: none;
    background-color: transparent;
    outline: none;
    z-index: 3;

    .button_name {
      color: $off-white;
    }

    svg {
      filter: drop-shadow(0px -3px 6px rgba(0, 0, 0, 0.5));
      path {
        fill: $off-white;
      }
    }

    &.light {
      .button_name {
        color: $dark-brown;
      }

      svg {
        path {
          fill: $off-white;
        }
      }
    }

    &.dark {
      .button_name {
        color: $off-white;
      }

      svg {
        path {
          fill: $dark-grey;
        }
      }
    }
  }
}
