@use '../sass/main.scss' as *;

.error {
  // padding-left: 92px;
  margin-bottom: 52px;
  width: 100%;
  height: 335.65px;
  z-index: 3;

  color: $dark-brown;
  text-align: center;
  font-family: Passero One;
  font-size: 57px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  position: relative;
  padding-top: 139px;
}

.swiper {
  padding-top: 37px;
  padding-left: 92px;
  margin-bottom: 52px;
  width: 100%;
  height: 335.65px;
  z-index: 3;

  .slide {
    width: 489.53px;
    height: 298.65px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    border-radius: 5px;
    border: 3px solid $white-2;
    overflow: hidden;

    &__background {
      width: 489.53px;
      height: 298.65px;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      z-index: -1;
    }

    &__top {
      padding: 11px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__star {
        width: 24px;
        height: 24px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }

      & svg {
        width: 24px;
        height: 24px;
        color: $white-2;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
    }

    &__footer {
      padding: 27.4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 6.07px;
      &__heading {
        color: $white-2;
        font-family: 'Passero One', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &__bottom {
        width: 434.7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__tags {
        @include flex(flex-start, center, 8.22px);
      }

      &__tag {
        background-color: $light-brown;
        border-radius: 65px;
        display: flex;
        width: 71.238px;
        height: 17.353px;
        justify-content: center;
        align-items: center;
        color: $white-2;

        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
      }

      &__longTag {
        background-color: $light-brown;
        border-radius: 65px;
        display: flex;
        width: 105px;
        height: 17.353px;
        justify-content: center;
        align-items: center;
        color: $white-2;

        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
      }

      &__button {
        width: 84.252px;
        height: 22.833px;
        border-radius: 5px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        outline: none;
        border: none;
        background-color: $white-2;
        z-index: 2;
        // justify-self: flex-end;
        display: block;

        color: $dark-brown;
        text-align: center;
        font-family: 'Passero One', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &:hover {
          color: $white-2;
          background-color: $realm-green;
          transition: all 0.3s ease-in;
        }
      }
    }
  }
}

.container {
  cursor: grab;
}
