@use '../sass/main.scss' as *;
.cards_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 520px;
  flex-wrap: wrap;
  gap: 20px;
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 250;

  .loading_gif {
    width: 100%;
    height: auto;
  }
}
.main {
  padding-left: 131px;
  padding-top: 68px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 100px;

  @include media('<=tablet') {
    flex-direction: column;
    padding: 0;
    padding-top: 34px;
    justify-content: center;
    align-items: center;
  }

  .wrapper {
    display: flex;
    flex-direction: column;

    @include media('<=tablet') {
      margin: 0 auto;
      width: 70%;
      align-items: center;
    }

    &__heading {
      width: 364px;
      height: 50px;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include media('<=phone-tab') {
        width: max-content;
      }

      &__text {
        color: $dark-brown;
        text-align: center;
        font-family: 'Passero One';
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.1px;

        @include media('<=phone-tab') {
          font-size: 30px;
          text-align: center !important;
        }
      }
    }

    &__box {
      width: 330px;
      height: 450px;
      border-radius: 10px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      margin-top: 32px;
      overflow: hidden;

      &__preview {
        height: 38.68px;
        background-color: $cheese-brown;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__previewText {
        color: $dark-brown;
        text-align: center;
        font-family: 'Passero One';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        width: 130.755px;
        height: 46.182px;
        padding-top: 13px;

        @include media('<=phone-tab') {
          font-size: 27px;
        }
      }

      &__imageBox {
        height: 323px;
        background-color: $white-2;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__image {
        width: 300px;
        height: 300px;
        border-radius: 50%;
      }

      &__upload {
        height: 45.46px;
        background-color: $cheese-brown;

        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      &__uploadText {
        color: $dark-brown;
        text-align: center;
        font-family: 'Passero One';
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &__remove {
        background: #e8d4b2;

        display: flex;
        justify-content: center;
        align-items: center;
        height: 42.53px;
        cursor: pointer;
      }

      &__removeText {
        color: #c39d7c;
        text-align: center;
        font-family: 'Passero One';
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .theme {
    width: 100%;

    @include media('<=tablet') {
      width: 70%;
    }
    &__appearance {
      padding-bottom: 25px;
      border-bottom: 1px solid $light-brown;
      &__title {
        color: #000;
        text-align: center;
        font-family: 'DM Sans';
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.1px;
        text-align: left;
      }

      &__text {
        color: $dark-brown;
        font-family: 'DM Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        padding-top: 10px;
        text-align: left;
      }
    }

    &__details {
      padding-top: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid $light-brown;
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 364px;

      @include media('<=phone-tab') {
        width: 100%;
      }

      &__label {
        color: $dark-brown;
        text-align: left;
        font-family: 'DM Sans';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        width: 364px;

        height: 32px;
      }

      &__input {
        width: 364px;
        box-sizing: border-box;
        height: 32px;

        border-radius: 4px;
        border: none;
        border: 0.5px solid #250503;
        background: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        outline: none;
        padding-left: 8px;

        color: rgba(37, 5, 3, 0.5);
        font-family: 'DM Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.46px;
        text-transform: capitalize;

        @include media('<=phone-tab') {
          width: 100%;
        }
      }

      &__button {
        align-self: end;
        display: block;
        padding: 4px 10px;
        border-radius: 4px;
        background: $realm-green;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);

        border: none;
        outline: none;
        width: 73px;

        color: $white-2;
        font-family: 'Passero One';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
      }
    }

    &__password {
      padding-top: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid $light-brown;
      &__heading {
        //   width: 364px;
        height: 32px;
        color: $dark-brown;
        text-align: left;
        font-family: 'DM Sans';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
      }

      &__form {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 23px;
      }

      &__box {
        display: flex;
        flex-direction: column;
        position: relative;

        & svg {
          position: absolute;
          width: 18px;
          height: 18px;
          color: $realm-green;
          left: 334px;
          top: 33px;
          cursor: pointer;
        }
      }

      &__label {
        color: $dark-brown;
        font-family: 'DM Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 162.5% */
        letter-spacing: 0.46px;
        text-transform: capitalize;
        text-align: left;
        height: 26px;
      }

      &__input {
        width: 364px;
        box-sizing: border-box;
        height: 32px;

        border-radius: 4px;
        border: none;
        border: 0.5px solid #250503;
        background: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        outline: none;
        padding-left: 8px;

        color: rgba(37, 5, 3, 0.5);
        font-family: 'DM Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.46px;
        text-transform: capitalize;
      }

      &__button {
        align-self: end;
        display: block;
        padding: 4px 10px;
        border-radius: 4px;
        background: $realm-green;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);

        border: none;
        outline: none;
        width: 73px;

        color: $white-2;
        font-family: 'Passero One';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
        margin-top: 8px;
        margin-left: 291px;
      }
    }

    &__deactivate {
      padding-top: 25px;
      padding-bottom: 67px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 364px;

      @include media('<=phone-tab') {
        width: 100%;
      }

      &__heading {
        color: $dark-brown;
        text-align: left;
        font-family: 'DM Sans';
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.1px;
      }

      &__text {
        color: #b3261e;
        font-family: 'DM Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
      }

      &__button {
        align-self: end;
        display: block;
        width: 95px;
        height: 30px;
        border-radius: 4px;
        background: #b3261e;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);

        border: none;
        outline: none;

        color: $white-2;
        font-family: 'Passero One';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
      }
    }
  }
}
