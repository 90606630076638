.content-placeholder {
    background-color: #d9d5d9;
    border-radius: 3px;
    position: relative;
    overflow: hidden;

    &.no-of-items {
        width: 130px;
        height: 25px
    }

    &.img-placeholder {
        width: 100px;
        height: 100px
    }

    &.btn-placeholder {
        width: 100%;
        height: 40px;
    }

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0));
        animation: shimmer 5s infinite;
        content: '';

    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }

    }
}