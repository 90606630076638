@use '/src/assets/sass/index.scss' as *;

.save_modal_input_container {
  position: relative;

  & svg {
    position: absolute;
    height: 20px;
    width: 20px;
    right: 15px;
    top: 18.5px;
  }
}

.save_modal_input {
  width: 400px;
  border: 1px solid $white;
  padding: 10px 20px;
  color: $white;
  border-radius: 5px;

  @include media("<=tablet"){
    width: 80vw;
  }

  input {
    font-family: 'Passero One';
    font-size: 20px;
    font-weight: 400;
    color: $white;
  }

  &__realmName {
    input {
      font-family: 'Passero One';
      font-size: 20px;
      font-weight: 600;
      color: $white;
      // text-transform: uppercase;
    }
  }

  &::after,
  &::before {
    border: none !important;
    outline: none !important;
  }

  &:hover {
    border: 1px solid $white;

    &::after,
    &::before {
      border: none !important;
      outline: none !important;
    }
  }
}

.save_modal_save_button {
  display: block;
  background-color: $orange !important;
  color: $white !important;
  font-family: 'Passero One';
  font-size: 20px;
  font-weight: 400;
  padding: 10px 20px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  @include media("<=tablet"){
    width: 80vw;
  }

  // &:disabled{
  //     background-color: #ff6846bf!important;
  //     cursor:not-allowed!important;
  // }
}

.save_modal_button {
  background-color: $realm-green !important;
  color: $off-white !important;
  padding: 10px 20px;

  &:hover {
    background-color: $milky-brown !important;
    color: $dark-brown !important;
  }
}

.preview_image {
  height: 123px;
  width: 123px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 16px;
}
