@use '../sass/main.scss' as *;

.realmGridContainer {
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  padding: 50px;

  @include media('<=phone-tab') {
    padding: 0;
    padding-top: 20px;
  }
}

.realmGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  //display: flex;
  //grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  //grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  gap: 20px;
  justify-content: flex-start;
  z-index: 3;
  width: 80%;

  .error {
    //  margin-bottom: 52px;
    width: 100%;
    height: 212px;

    color: $dark-brown;
    text-align: center;
    font-family: Passero One;
    font-size: 57px;
    font-style: normal;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: -0.25px;
    position: relative;
    //  padding-top: 139px;
  }

  .realm {
    // width: 412px; /* Replaced toRem(412) with 412px */
    //width: calc(25% - 15px); /* Replaced toRem(412) with 412px */
    width: 100%;
    height: 300px;
    min-width: 320px;
    //height: 252px; /* Replaced toRem(252) with 252px */
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: relative;
    border-radius: 5px;
    border: 3px solid $white-2;
    overflow: hidden;

    &__background {
      // width: 412px;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      z-index: -99;
    }

    &__top {
      //padding: 11px; /* Replaced toRem(11) with 11px */
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 3;

      &__star {
        width: 24px;
        height: 24px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }

      & svg {
        width: 24px;
        height: 24px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        color: $white-2;
      }
    }

    &__footer {
      //padding: 20px; /* Replaced toRem(20) with 20px */
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 3;
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 6.07px; /* Replaced toRem(6.07) with 6.07px */
      width: 100%;
      &__heading {
        color: $white-2;
        font-family: 'Passero One', sans-serif;
        font-size: 18px; /* Replaced toRem(18) with 18px */
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &__bottom {
        // width: 372px;
        width: 100%;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }

      &__tags {
        @include flex(flex-start, center, 8.22px);
        flex-wrap: wrap;
        position: relative;
        align-items: center;
        text-align: center;
        align-content: center;
        gap: 10px;
        padding: 6px;
        margin-bottom: 10px;
      }

      &__tag {
        background-color: $light-brown;
        border-radius: 65px;
        display: flex;
        width: 100px;
        height: 22px;
        justify-content: center;
        align-items: center;
        color: $white-2;

        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0.1px;

        @include media('<=tablet') {
          width: 80px;
        }
      }

      &__longTag {
        background-color: $light-brown;
        border-radius: 65px;
        display: flex;
        width: 105px;
        height: 17.353px;
        justify-content: center;
        align-items: center;
        color: $white-2;

        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;

        @include media('<=tablet') {
          width: 90px;
        }
      }

      &__button {
        width: 84.252px;
        height: 22.833px;
        border-radius: 5px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        outline: none;
        border: none;
        background-color: $white-2;
        z-index: 2;
        // justify-self: flex-end;
        display: block;

        color: $dark-brown;
        text-align: center;
        font-family: 'Passero One', sans-serif !important;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &:hover {
          color: $white-2;
          background-color: $realm-green;
          transition: all 0.3s ease-in;
        }
      }
    }
  }

  // .realm-1 {
  //   background: center / cover no-repeat
  //     url('../../assets/images/NewDashboard/burning_bg.png');
  // }

  // .realm-2 {
  //   background: center / cover no-repeat
  //     url('../../assets/images/NewDashboard/kingdon_bg.png');
  // }

  // .realm-3 {
  //   background: center / cover no-repeat
  //     url('../../assets/images/NewDashboard/kingdom_old.png');
  // }

  // .realm-4 {
  //   background: center / cover no-repeat
  //     url('../../assets/images/NewDashboard/kingdom_evening.png');
  // }

  // .realm-5 {
  //   background: center / cover no-repeat
  //     url('../../assets/images/NewDashboard/burning_bg.png');
  // }

  // .realm-6 {
  //   background: center / cover no-repeat
  //     url('../../assets/images/NewDashboard/kingdon_bg.png');
  // }

  // .realm-7 {
  //   background: center / cover no-repeat
  //     url('../../assets/images/NewDashboard/kingdom_old.png');
  // }

  // .realm-8 {
  //   background: center / cover no-repeat
  //     url('../../assets/images/NewDashboard/kingdom_evening.png');
  // }
}
