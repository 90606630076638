@use '../../../src/ui/sass/main.scss' as *;

.modal {
  width: 80%;
  height: 80%;
  max-width: 800px;
  margin-left: 5%;
  margin-top: 5%;
  background-color: transparent;
  background-image: url('../../assets//images/NewDashboard/wallpaper_light.png');
  display: flex;
  flex-direction: column;
  gap: 05px;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;

  background-size: cover;
  background-position: center;
  background-repeat: repeat-x;

  border: 20px solid #68583d;
  border-image-source: url('../../images/custom-border-image-3.webp');
  border-image-slice: 27;
  border-image-repeat: round round;

  &__bookmarkImg {
    position: absolute;
    top: 2%;
    left: 5%;
    width: 300px;
    height: 60px;
    z-index: 0;
  }

  &__container {
    @include flex(flex-start, center, 15px);
    gap: 25px;

    &__realmImage {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 4%;
      left: 7%;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    &__text {
      color: $white-2;
      font-family: Passero One;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      position: absolute;
      top: 5%;
      left: 14%;
      width: 300px;
      height: 60px;
      z-index: 0;
      z-index: 2;
    }
  }

  &__background {
    height: 100%;
    width: 100%;
  }

  .textContainer {
    z-index: 5;
    &__title {
      color: $dark-brown;
      font-family: Passero One;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin: 0;
    }

    &__info {
      color: $dark-brown;
      font-family: DM Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.1px;
      margin: 0;
      padding-top: 29px;
      z-index: 5;
      //   width: 100%;
    }
  }

  .actionsContainer {
    @include flex(flex-start, center, 25px);
    z-index: 5;
    min-width: 250px;
    width: 100%;

    .characterActions {
      
      border-radius: 4px;
      background-color: $beige-2;
      position: relative;
      min-width: 220px;
      max-width: 300px;
      width: 50%;

      &__topText {
        color: #49454f;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin: 0;
      }

      &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width:100%;
      }

      &__box {
        @include flex(flex-start, center, 5);
        width: 100%;
        height: 100%;        
        background-color: $white-2;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
        padding: 4px;
        gap: 10px;
      }

      &__boxImage {
        // height: 118.651px;
        display: flex;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        background-color: $light-brown;

        &__icon {
          width: 40px;
          height: 100%;
        }

        &__iconPink {
          width: 40px;
          height: 100%;
        }
      }

      &__boxImagePink {
        background-color: $pink;
      }

      &__boxFeature {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white-2;
        
        &__text {
          color: $dark-brown;
          font-family: DM Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          width: 100%;
          text-align: center;
          margin: 0;
        }
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 80%;
      max-width: 500px;

      &__box {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
        
        &__tags {
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px;
          align-items: center;
          gap: 5.5px;
          z-index: 10;
       
        }

        &__tag {
          display: flex;
          width: 100px;
          height: 24px;
          justify-content: center;
          align-items: center;
          gap: 2.5px;
          border-radius: 65px;
          background-color: $white-2;
          padding-left: 5px;
          padding-right: 5px;

          & svg {
            width: 7px;
            height: 7px;
            color: #000;
            cursor: pointer;
          }
        }

        &__tagText {
          color: #000;
          text-align: center;
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.1px;
        }

        &__label {
          color: $dark-brown;
          font-family: Passero One;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.1px;         
        }

        &__innerLabel {
          color: #1d1b20;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;         
          z-index: 2;
          transition: 0.3s ease-out;
        }

        &__innerLabelTags {
          color: #1d1b20;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
          z-index: 2;
          transition: 0.3s ease-out;
        }

        &__innerLabelTagsFocused {
          color: #49454f;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }

        &__innerLabelFocused {
          color: #49454f;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }

        &__innerLabelDescription {
          color: #1d1b20;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
          z-index: 2;
          transition: 0.3s ease-out;
        }

        &__innerLabelDescriptionFocused {
          top: 50px;
          color: #49454f;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }

        &__diceDiv {
          width: 33px;
          height: 33px;
          background-color: $white-2;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 2;
          // margin-right: 13px;
        }

        &__dice {
          width: 21px;
          height: 24px;
        }

        &__input {
          box-sizing: border-box;
          width: 100%;
          border-radius: 4px;
          background-color: $beige-2;
          outline: none;
          border: none;

          color: #1d1b20;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
          transition: 0.3s ease-out;
          position: relative;
        }

        &__inputDescription {
          box-sizing: border-box;
          position: relative;
          width: 80%;
        }

        &__input::placeholder {
          color: #1d1b20;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
        }
      }

      &__button {
        display: block;
        width: 173px;
        height: 40px;
        background-color: $realm-green;
        border-radius: 4px;
        outline: none;
        border: none;
        align-self: flex-end;

        color: $white-2;
        text-align: center;
        font-family: Passero One;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }
  }
}

.replaceImageContainer {
  position: relative !important;
  .replaceImage {
    display: none !important;
    transition: all 0.3s ease-in-out;
  }
}

.replaceImageContainer:hover {
  // border-radius: 4px !important;
  .replaceImage {
    display: flex !important;
    position: absolute !important;
    top: 400px !important;
    left: 50% !important;
    gap: 10px !important;
    flex-wrap: nowrap !important;
    transform: translateX(-50%);
  }
}

  /*
  &__bottomGradient {
    height: 80%;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(211, 180, 129, 0) 0%,
      #d3b481 110%
    );
  }

  &__midGradient {
    position: absolute;
    bottom: 300px;
    left: -9px;
    height: 195px;
    width: 1493px;
    background: linear-gradient(
      180deg,
      #cfae79 0%,
      rgba(207, 174, 121, 0) 100%
    );
    z-index: 2;
  }

  &__newGradient {
    position: absolute;
    bottom: 461px;
    left: -9px;
    height: 195px;
    width: 1493px;
    background: linear-gradient(
      180deg,
      rgba(207, 174, 121, 0) 0%,
      #cfae79 100%
    );
    z-index: 2;
  }

  &__lastGradient {
    position: absolute;
    bottom: 461px;
    left: -9px;
    height: 195px; 
    width: 1493px;
    background: linear-gradient(
      180deg,
      rgba(207, 174, 121, 0) 0%,
      #cfae79 100%
    );
    z-index: 2;
  }

  &__extraGradient {
    position: absolute;
    bottom: 300px;
    left: -9px;
    height: 195px;
    width: 1493px;
    background: linear-gradient(
      180deg,
      #cfae79 0%,
      rgba(207, 174, 121, 0) 100%
    );
    z-index: 2;
  }

  &__topGradient {
    position: absolute;
    bottom: 300px;
    left: -9px;
    height: 195px; 
    width: 1493px;
    background: linear-gradient(
      180deg,
      #cfae79 0%,
      rgba(207, 174, 121, 0) 100%
    );
    z-index: 2;
  }
  */
  