@use '/src/assets/sass/index.scss' as * ;

.history_wrapper{
    max-height: 450px!important;
    overflow-y: scroll;

    &::-webkit-scrollbar{
        width: 5px;
        padding-right: 10px;
      }
      
      
      /* Thumb (the draggable part of the scrollbar) */
      &.light{
        /* Firefox*/
        &.scrollbar {
          scrollbar-width: thin; 
          scrollbar-color: $toolbar-background-light transparent !important;
        }
        
        &::-webkit-scrollbar-thumb, &.scrollbar-thumb {
          background-color: $milky-brown !important;
          border-radius: 5px;
          padding-right: 10px;
        } 
        &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover {
          background-color: $toolbar-divider-light  !important;
        }
      }
      
      &.dark{
        /* Firefox*/
        &.scrollbar {
          scrollbar-width: thin; 
          scrollbar-color: $toolbar-background-dark transparent !important;
        }
    
        &::-webkit-scrollbar-thumb, &.scrollbar-thumb {
          background-color: $toolbar-divider-dark !important;
          border-radius: 5px;
          padding-right: 10px;
        }
        &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover {
          background-color: $light-grey !important;
        }
      }
}