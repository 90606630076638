@use '../sass/main.scss' as *;

.layout {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;

  @include media('<=phone-tab') {
    width: 100%;
  }

  &__heading {
    padding-left: 131px;
    padding-top: 68px;

    color: $dark-brown;
    // text-align: center;
    font-family: 'Passero One';
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;

    @include media('<=phone-tab') {
      padding-left: 0;
      font-size: 30px;
      text-align: center;
    }
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 54px;

    @include media('<=phone-tab') {
      width: 100%;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 50px;

      @include media('<=phone-tab') {
        width: 70%;
      }

      &__heading {
        width: 358px;
        height: 50px;

        color: $dark-brown;
        font-family: 'DM Sans';
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.1px;
      }

      &__box {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      &__subscription {
        color: $dark-brown;

        font-family: 'DM Sans';
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.1px;
      }

      &__container {
        width: 591px;
        height: 218px;
        border-radius: 10px;
        border: 1px solid $light-brown;
        background: $beige;

        @include media('<=phone-tab') {
          width: 100%;
          height: max-content;
        }
      }

      &__cancelButton {
        width: 190px;
        height: 30px;
        display: block;
        padding: 4px 10px;
        border-radius: 4px;
        background: #8c1d18;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        // box-sizing: border-box;

        border: none;
        outline: none;
        width: 190px;
        margin-bottom: 166px;

        color: $white-2;
        font-family: 'Passero One';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
      }

      &__payment {
        padding-top: 53px;
        padding-left: 57px;

        @include media('<=phone-tab') {
          padding: 0;
          padding: 20px;
        }

        &__box {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          @include media('<=phone-tab') {
            flex-direction: column;
            gap: 20px;
            align-items: center;
          }
        }

        &__visa {
          width: 45.387px;
          height: 38.75px;
          border-radius: 4px;
          border: 0.5px solid #ada392;
        }

        &__visaText {
          width: 179px;
          padding-left: 10px;

          color: $dark-brown;
          font-family: 'DM Sans';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.1px;
        }

        &__visaButton {
          display: block;
          padding: 4px 10px;
          border-radius: 4px;
          background: $realm-green;
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);

          border: none;
          outline: none;
          width: 73px;
          margin-left: 50px;

          color: $white-2;
          font-family: 'Passero One';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.46px;
          text-transform: uppercase;

          @include media('<=phone-tab') {
            margin-left: 0;
          }
        }

        &__info {
          color: #b3261e;
          font-family: 'DM Sans';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.1px;
          text-decoration-line: underline;
          padding-top: 55px;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      &__package {
        padding-top: 20px;
        padding-left: 70px;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        @include media('<=phone-tab') {
          flex-direction: column;
          gap: 20px;
          padding-left: 0;
        }

        &__background {
          width: 100px;
          height: 100px;
        }

        &__subscriptionContent {
          display: flex;
          flex-direction: column;
          gap: 25px;

          @include media('<=phone-tab') {
            gap: 20px;
          }
        }

        &__name {
          //   padding: 18px 16px;

          color: $dark-brown;
          //   text-align: center;
          font-family: 'Passero One';
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.1px;
        }

        &__price {
          //   height: 34px;
          //   padding: 0px 16px;

          color: $dark-brown;
          text-align: center;
          font-family: 'Passero One';
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.1px;
        }

        &__month {
          color: $dark-brown;
          font-family: 'Passero One';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.1px;
        }

        &__actionsBox {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 50px;
          padding-left: 70px;
          padding-bottom: 19px;

          @include media('<=phone-tab') {
            flex-direction: column;
            gap: 20px;
            padding-left: 0;
          }
        }

        &__text {
          color: $dark-brown;
          font-family: 'DM Sans';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.1px;
          width: 309px;

          @include media('<=phone-tab') {
            text-align: center;
            width: 100%;
          }
        }

        &__button {
          display: block;
          padding: 4px 10px;
          border-radius: 4px;
          background: $realm-green;
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);

          border: none;
          outline: none;
          width: 81px;

          color: $white-2;
          font-family: 'Passero One';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.46px;
          text-transform: uppercase;
        }
      }
    }
  }
}
