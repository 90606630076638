@use '/src/assets/sass/index.scss' as * ;

input[type="text"]{
    &.MuiInputBase-input.MuiInput-input.Mui-disabled{
        color: $off-white!important;
        -webkit-text-fill-color: $off-white!important;
    }
}

.save_modal_text_area{
    .MuiInputBase-root.MuiInput-root{
        width: 400px;
        border: 1px solid $white;
        padding: 10px 20px;
        color: $white;
        border-radius: 5px;

        &::after, &::before{
            border: none!important;
            outline: none!important;
        }

        input{
            font-family: 'Passero One';
            font-size: 20px;
            font-weight: 400;
            color: $white;
        }

        &:hover{
            border: 1px solid $white;

            input{
                border: none!important;
                outline: none!important;
            }
    
            &::after, &::before{
                border: none!important;
                outline: none!important;
            }
        }
    }
}

.save_modal_button.Mui-disabled{
    background-color: darken($color: $realm-green, $amount: 10)!important;
    color: darken($color: $off-white, $amount: 40)!important;
}