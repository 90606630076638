@use '/src/assets/sass/index.scss' as * ;

.fixedFilterWrapper{
    width: 100%;
    padding: 5px 11px;
    box-sizing: border-box;
    // display: flex;
    // flex-direction: column;
    row-gap: 8px;
    
    &.light{
        background-color: $toolbar-background-light;
        border-top: 1px solid #5d5d5d;
    }
    &.dark{
        border-top: 1px solid black;
        background-color: $toolbar-background-dark;
    }

    &.forGeneral{
        padding: 0;
        border: none!important;
        background-color: transparent!important;
    }
}

// .searchBox{
//     border-bottom: 1px solid #fff!important;
    
//     & .MuiInputBase-root.MuiInput-root{
//         input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch{
//             color: #fff!important;
//             font-family: "DM Sans"!important;
//             font-weight: 400!important;
//             font-size: 15px!important;
//             line-height: 26px!important;
//             letter-spacing: 0.46px!important;
//         }
        
//         &::after, &::before{
//             border: none!important;
//             outline: none!important;
//         }
//     }
// }