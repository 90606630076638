@use '/src/assets/sass/index.scss' as * ;

.tab_panel{
    position: relative;
    border-radius: 0px 0px 4px 4px;
    min-height: $panel-tab-height;
    width: $panel-tab-width;
    max-height: $panel-tab-height;
    overflow-y: scroll;
    overflow-x: hidden;
    
    &.top_border{
      border-top: 1px solid !important;
    }

    &::-webkit-scrollbar{
        width: 5px;
        // padding-right: 10px;
    }
    
    &.light{
        background-color: $toolbar-background-light;
        border-top-color: $light-grey!important;
        &.scrollbar {
            scrollbar-width: thin; 
            scrollbar-color: $toolbar-background-light transparent !important;
          }
          
          &::-webkit-scrollbar-thumb, &.scrollbar-thumb {
            background-color: $milky-brown !important;
            border-radius: 5px;
            padding-right: 10px;
          } 
          &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover {
            background-color: $toolbar-divider-light  !important;
          }
    }
    
    &.dark{
        background-color: $toolbar-background-dark;
        border-top-color: black!important;

        &.scrollbar {
            scrollbar-width: thin; 
            scrollbar-color: $toolbar-background-dark transparent !important;
          }
      
          &::-webkit-scrollbar-thumb, &.scrollbar-thumb {
            background-color: $toolbar-divider-dark !important;
            border-radius: 5px;
            padding-right: 10px;
          }
          &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover {
            background-color: $light-grey !important;
          }
    }
}

@media screen and (min-height:900px) {
    .tab_panel{
      max-height: $panel-tab-height-720;
    }
}

@media screen and (max-width:500px) {
    .tab_panel{
      width: 100vw!important;
      min-height: 600px;
    }
}
