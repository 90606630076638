// .MuiButtonBase-root.MuiCardActionArea-root {
//     position: relative;
//     border-radius: 66px;
//     background-color: transparent !important;
//   }
  
// .MuiButtonBase-root.MuiCardActionArea-root:hover {
// background-color: transparent !important;
// }
@use '/src/assets/sass/index.scss' as * ;

.imageList{
  overflow-y: auto!important;
  max-height: fit-content;
  &::-webkit-scrollbar{
    display: none!important;
  }
  &.scrollbar {
    display: none!important;
  }
}

// .imageList, .text_interface_wrapper, .panel_overflow{
//   margin: 0px;
//   max-height: $panel-tab-height;
  
//   &::-webkit-scrollbar{
//     width: 5px;
//   }
  
  
//   /* Thumb (the draggable part of the scrollbar) */
//   &.light{
//     /* Firefox*/
//     &.scrollbar {
//       scrollbar-width: thin; 
//       scrollbar-color: $toolbar-background-light transparent !important;
//     }
    
//     &::-webkit-scrollbar-thumb, &.scrollbar-thumb {
//       background-color: $milky-brown !important;
//       border-radius: 5px;
//       padding-right: 10px;
//     } 
//     &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover {
//       background-color: $toolbar-divider-light  !important;
//     }
//   }
  
//   &.dark{
//     /* Firefox*/
//     &.scrollbar {
//       scrollbar-width: thin; 
//       scrollbar-color: $toolbar-background-dark transparent !important;
//     }

//     &::-webkit-scrollbar-thumb, &.scrollbar-thumb {
//       background-color: $toolbar-divider-dark !important;
//       border-radius: 5px;
//       padding-right: 10px;
//     }
//     &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover {
//       background-color: $light-grey !important;
//     }
//   }

//   .active-background, .active-background:hover{
//     padding: 10px;
//     transition: $standard-transition;
//     img{
//       transition: $standard-transition;
//       filter: drop-shadow(0 0 5px #ffffffb3) drop-shadow(0 0 10px #ffffffb3) drop-shadow(0 0 15px #ffffffb3);
//     }
//   }

// }

.text_editor_field, .text_editor_autocomplete, .text_editor_size{
  &:hover, &:focus{
    border: none!important;
    outline: none!important;
  }

  .MuiInputBase-root.MuiInput-root{
    color: $white!important;

    &::after, &::before{
      outline: none!important;
      border: none!important;
      border-color: transparent !important;
    }
  }
}

.text_editor_autocomplete{
  .MuiInputBase-root.MuiInput-root{
    padding: 10px 16px ;
  }
  path{
    fill: $white!important;
  }
}

.text_interface_wrapper{
  span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label{
    color: $white!important;
  }

  .MuiCheckbox-root.Mui-checked{
    color: $milky-brown !important;
  }

  .MuiCheckbox-root{
    border-color:$white !important;
  }
}
.alignment-wrapper{
  button.MuiButtonBase-root.MuiToggleButton-root{
    height: 40px !important;
    width: 40px !important;
    transition: $standard-transition;

    &:hover, &.Mui-selected{
      filter: $toolbar-hover-glow;
    }
  }
}

@media screen and (min-height:900px) {
    .text_interface_wrapper{
      max-height: $panel-tab-height-720;
    }
}
